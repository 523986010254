@import url(../../ratchet/css/ratchet.min.css);
@import url(../../ratchet/css/ratchet-theme-ios.min.css);
/* @import url(../../spectre/css/spectre.min.css); */

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 2rem;
}

/* for links that have no href, but only an onClick */
a.link {
  cursor: pointer;
}

.navbar {
  background-color: #fefefe;
}

/* flexbox */
.flex-container {
  position: relative;
  height: calc(100% - 44px - 4rem); /* 100% - topBar-height - .m2-margin */
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
}
.flex-item {}

/* space/margin/etc. */
.m1 { margin: 1rem; }
.m2 { margin: 2rem; }

/* text helpers */
.text-lowercase { text-transform: lowercase; }
.text-uppercase { text-transform: uppercase; }

/* toast */
.toast-container {
  position: absolute;
  top: 44px;
  width: 100%;
  display: flex;
  opacity: 0.8;
  justify-content: center;
  flex-direction: column;
}

.toast {
  background: rgba(69,77,93,.9);
  border: .05rem solid #454d5d;
  border-color: #454d5d;
  color: #fff;
  display: block;
  padding: .4rem;
  width: 100%;
  font-size: 86%;
}

.toast.toast-primary {
  background: rgba(87,85,217,.9);
  border-color: #5755d9
}

.toast.toast-success {
  background: rgba(50,182,67,.9);
  border-color: #32b643
}

.toast.toast-warning {
  background: rgba(255,183,0,.9);
  border-color: #ffb700
}

.toast.toast-error {
  background: rgba(232,86,0,.9);
  border-color: #e85600
}

.toast a {
  color: #fff;
  text-decoration: underline
}

.toast a.active,.toast a:active,.toast a:focus,.toast a:hover {
  opacity: .75
}

.toast .btn-clear {
  margin: 4px -2px 4px 4px
}

/* buttons */
.btn-positive {
  background-color: #47b65a;
  border-color: #47b65a;
}

/* loading */
/* .loading { */
/*   position: fixed; */
/*   z-index: 999; */
/*   height: 2em; */
/*   width: 2em; */
/*   overflow: show; */
/*   margin: auto; */
/*   top: 0; */
/*   left: 0; */
/*   bottom: 0; */
/*   right: 0; */
/* } */


.loading {
  color: transparent!important;
  min-height: .8rem;
  pointer-events: none;
  position: relative
}

.loading::after {
  animation: loading .5s infinite linear;
  border: .1rem solid #5755d9;
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: .8rem;
  left: 50%;
  margin-left: -.4rem;
  margin-top: -.4rem;
  position: absolute;
  top: 50%;
  width: .8rem;
  z-index: 1
}

.loading.loading-lg {
  min-height: 2rem
}

.loading.loading-lg::after {
  height: 1.6rem;
  margin-left: -.8rem;
  margin-top: -.8rem;
  width: 1.6rem
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* forms */
::placeholder {
  color: #ccc;
}

/* toggles */
.toggle {
  cursor: pointer;
}

/* church view screen */
.profile-view {

}
.profile-view h1.heading {
  font-size: 120%;
  margin: .67em 0;
}

/* menu */
.menu {
  position: absolute;
  top: 15px;
  right: 15px;
}
.menu-icon {
  padding: 4px 10px;
  cursor: pointer;
}
.menu-icon span.icon {
  position: relative;
  top: 2px; /* to align icon with text */
}
.menu .popover.visible {
  display: block;
}
.menu .popover {
  left: auto;
  right: 10px;
  top: 60px;
}
.menu .popover:before {
  left: 67%;
}
.menu .popover .table-view-cell {
  padding-right: 11px;
}

p.help {
  font-size: 70%;
}
/* .menu-content { */
/*   display: none; */
/* } */
/* .menu.active .menu-content { */
/*   display: block; */
/* } */
.tabs-tabs {
  margin-bottom: 1rem;
}

.float-left { float: left; }
.float-right { float: right; }

/** text utils **/
.text-center { text-align: center; }
.text-right { text-align: right; }

.text-help {
  font-size: .86rem;
}
